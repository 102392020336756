import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import ImageTag from "gatsby-image";

const stats = [
  { label: 'Founded', value: '2011' },
  { label: 'Employees', value: '12' },
  { label: 'Customers', value: '400+' },
  { label: 'Shopping\u00A0Centres', value: '95' },
  { label: 'Cards\u00A0Issued', value: '4.0M' },
  { label: 'Funds\u00A0Processed', value: '$500M' },
]

const Company = () => {
  const { team } = useStaticQuery(graphql`
    query {
      team: file(name: { eq: "team-143" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="relative py-16 bg-white overflow-hidden">
      <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
          <svg
            className="absolute top-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute bottom-12 left-full transform translate-x-32"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="d3eb07ae-5182-43e6-857d-35c643af9034"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-left sm:text-center text-highlight font-semibold tracking-wide uppercase">
              About WAIVPAY
            </span>
            <span className="mt-2 block text-3xl text-left sm:text-center leading-8 font-extrabold tracking-tight text-primary sm:text-4xl">
              <span className="block sm:inline">WAIVPAY,&nbsp;</span>
              <span className="block sm:inline">A Better WAY.</span>
            </span>
          </h1>
          <figure className="relative lg:mx-auto max-w-md sm:max-w-3xl lg:max-w-none py-6 lg:py-12">
            <ImageTag Tag="div" className="shadow-lg rounded-lg" fluid={team.sharp.fluid} />
            <figcaption className="p-2 text-sm text-gray-400 text-left">The WAIVPAY Team</figcaption>
          </figure>
          <p className="mt-1 sm:mt-4 text-xl text-gray-500 leading-8">
            The team that works with you is as diverse as the solutions we provide.
            From NZ to Columbia, across to Brazil, passing through Germany, Ireland, and England to homegrown in Australia.
          </p>
        </div>
        <div className="mt-6 prose prose-lg text-gray-500 mx-auto">
          <p>
            WAIVPAY pride themselves in having developed market firsts for the last 15 years in pioneered payment solutions for gifting, loyalty, and rewards.
          </p>
          <p>
            Our technology is not only built inhouse but supported by a team that has over seventy years of experience in the industry.
            Glossy power points and marketing statements are replaced by substance and knowledge gained by building our technology for our customers.
          </p>
          <p>
            Our flexible solutions change the way your customers access, gift and redeem money and ultimately, how they interact with you.
            These solutions include open and closed loop payment systems, customised reward and incentive platforms and branded gift card programs.
            Our bespoke products are designed to integrate easily into your current systems and processes, creating an effective and efficient solution, tailored to your requirements.
            We offer a suite of solutions available to shopping centres, corporate organisations and government bodies to provide an end to end solution.
          </p>
          <p>
            You can learn more about what we offer <Link to='/' className="text-highlight">here</Link>, or speak to a member of the <Link to='/contact' className="text-highlight">WAIVPAY team</Link> to discuss how we can best meet your needs.
          </p>
        </div>
      </div>

      <div className="relative px-4 sm:px-6 lg:px-8 mt-6">
        <div className="text-lg max-w-prose mx-auto">
          <Link to="/about/board" className="text-lg font-medium text-highlight hover:text-highlight-dark">
            WAIVPAY Board <span aria-hidden="true">&rarr;</span>
          </Link>
          <Link to="/about/team" className="ml-6 text-lg font-medium text-highlight hover:text-highlight-dark">
            WAIVPAY Team <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Company;
