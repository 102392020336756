import React from "react";
import SEO from "@components/seo";
import Layout from "@components/layout";
import Footer from "@components/footers/dark";
import Company from "./company/index.js";

const About = () => (
  <>
    <SEO title="Company" />
    <Layout>
      <Company/>
    </Layout>
    <Footer/>
  </>
);

export default About;
