import React from "react";
import Footer from "@components/footers/dark";
import Layout from "@components/layout";
import SEO from "@components/seo";
import Company from "./Company.js";
import MenuBar from "@components/menuBar/MenuBar";

const CompanyPage = () => (
  <>
    <SEO title="Giftcards Kiosks" />
    <MenuBar/>
    <Layout>
      <Company/>
    </Layout>
  </>
);

export default CompanyPage;
